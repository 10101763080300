import styled from 'styled-components';
import { deviceSize } from '../../common/utils';

export const SectionWrapper = styled.section`
  padding: 160px 0px 160px;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  @media (max-width: 1440px) {
    padding: 80px 0 0px;
  }
  @media (max-width: ${deviceSize.laptop.px}) {
    min-height: 50vh;
  }
  @media (max-width: 480px) {
    padding: 80px 0 0px;
  }
  button {

  }

  .page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 60px 0px;
  }

  .members {
    display: grid;
    justify-content: space-between;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 980px) {
    .members {
        grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 680px) {
      .members {
          grid-template-columns: 1fr;
      }
  }
`;