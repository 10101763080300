import React from 'react';

import { Container, Text } from '@components/index';
import { SectionWrapper } from './teamSection.style';
import { graphql, useStaticQuery } from 'gatsby';
import { TeamMember } from './TeamMember/TeamMember';

export function TeamSection() {
  const team = useStaticQuery(graphql`
  {
    allAirtable(
      filter: {table: {eq: "Team 🏈"}, data: {Active: {eq: true}} }
      sort: {order: ASC, fields: data___index}
    ) {
      nodes {
        data {
          index
          role
          name
          email
          linkedin
          phone
          picture {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
    }
  }
`);

  return (
    <SectionWrapper>
      <Container>
        <section className="page-header">
          <Text content="The Team" style={{ fontSize: "2.2em", fontWeight: 700, alignSelf: 'center' }} />
          <Text content="Through Talent Swipe we help IT organisations grow by guiding talent into exciting careers. To do this we’ve built a great team of consultants and technical recruiters. " style={{ textAlign: 'center', alignSelf: 'center', color: 'gray', width: '70%' }} />
        </section>
        <section className="members">
          {team.allAirtable.nodes.map((entry, key) => (
            <TeamMember data={entry.data} key={key} />
          ))}
        </section>
      </Container>
    </SectionWrapper>
  )
}