import styled from 'styled-components';

export const Wrapper = styled.section`
.card {
  display: flex;
  flex-direction: column;
  padding: 1em;
  border-radius: 0.8em;
  background-color: #fefefe;
  box-shadow: 0px 6px 15.98px 1.02px rgba(56, 86, 122, 0.1);
  border-radius: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.card__image {
  width: 100px;
  height: 100px;
  background-color: #ff4e00;
  background-image: linear-gradient(315deg, #ff4e00 0%, #ec9f05 25%);  
  border-radius: 100%;
  border: 4px solid transparent;
}

.card__info {
  margin: 1em 0;
  list-style-type: none;
  padding: 0;
}

.card__info li {
  display: inline-block;
  text-align: center;
  padding: 0.5em;
}

.card__info__stats {
  color: var(--main-accent-color);
  font-weight: bold;
  font-size: 1.2em;
  display: block;
}

.card__info__stats + span {
  color: #969798;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
}

.card__text h2 {
  text-align: center;
  margin-bottom: 0.3em;
  font-size: 1.4em;
  color: #000;
}

.card__text p {
  margin: auto;
  text-align: center;
  color: #999;
  font-size: 0.95em;
}

.card__action {
  display: flex;
  justify-content: space-around;
}

.card__action__button {
  padding: 0.9em 1.3em;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  position: relative;
}

.card__action__button:before {
  content: "";
  position: absolute;
  border-radius: 0.5em;
  transition: all 0.35s ease-in-out;
}

.card__action__button:hover:before {
  top: -6px;
  bottom: -6px;
  left: -6px;
  right: -6px;
}

.card__action--follow {
  background-color: var(--main-accent-color);
}

.card__action__button:hover:before {
  border: 4px solid var(--main-accent-color);
}

.card__action--message {
  background-color: var(--secondary-accent-color);
}

.card__action--message:hover:before {
  border: 4px solid var(--secondary-accent-color);
}

em {
  display: block;
  height: 2px;
  width: 3rem;
  background: #e0e1e5;
  margin: 1em 0 0 0;
}

@media (min-width: 425px) {
  .card {
    padding: 3em;
  }

  .card:after {
    top: 50px;
    right: 160px;
  }

  .card__info li {
    padding: 1em;
  }
  .card__action__button {
    padding: 0.9em 1.8em;
  }
}
`

export const SocialMediaButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
  @media (max-width: 425px) {
    justify-content: flex-start;
  }

  .contact-button {
    border-radius: 6px;
    background-color: #808080;
    &:hover {
      box-shadow: -6.691px 7.431px 20px 0px rgba(255, 131, 157, 0.2);
    }
    > span {
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
`;