import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import Icon from "react-icons-kit";
import { ic_phone } from "react-icons-kit/md/ic_phone";
import { ic_email } from "react-icons-kit/md/ic_email";
import { linkedin } from "react-icons-kit/fa/linkedin";

import { SocialMediaButtons, Wrapper } from './teamMember.style';
import { TeamMember as TeamMemberType } from '@common/types';

export function TeamMember({ data }: { data: TeamMemberType }) {
  return (
    <Wrapper>
      <div className="card">
        <GatsbyImage
          imgStyle={{ borderRadius: '100%' }}
          className="card__image"
          image={data.picture.localFiles[0].childImageSharp.gatsbyImageData}
          alt={`Picture ${data.name}`}
        />
      <div className="card__text">
        <h2>{data.name}</h2>
          <p>{data.role}</p>
        </div>
        <em></em>
        <div className="card__action">
          <SocialMediaButtons>
            {data.phone && (
              <a href={`tel:${data.phone}`}>
                <Icon icon={ic_phone} size={26} style={{ color: 'gray', padding: 8 }} />
              </a>
            )}
            <a href={`mailto:${data.email}`}>
              <Icon icon={ic_email} size={26} style={{ color: 'gray', padding: 8 }} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={data.linkedin}>
              <Icon icon={linkedin} size={26} style={{ color: 'gray', padding: 8 }} />
            </a>
          </SocialMediaButtons>
        </div>
      </div>
    </Wrapper>
  )
}