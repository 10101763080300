import React, { Fragment } from "react";
import { ResetCSS } from "../assets/css/style";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";

import { SEO } from "@components/index";
import { ContentWrapper, GlobalStyle } from "../containers/saas.style";
import { DrawerProvider } from "../contexts/DrawerContext";
import { Footer, Navbar } from "@containers/common";
import { theme } from "../common/theme";
import { TeamSection } from "@containers/team/TeamSection";

const TeamPage = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <SEO title="Team" />
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar notMainPage />
          </DrawerProvider>
        </Sticky>
        <TeamSection />
        <Footer notMainPage />
      </ContentWrapper>
    </Fragment>
  </ThemeProvider>
);

export default TeamPage;
